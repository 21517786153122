<template>
  <!--begin::Service Listing-->
  <div class="service-template">
    <PageHeaderCount
      v-if="false"
      moduleType="service"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_services"
      countkey="service_status_count"
    ></PageHeaderCount>
    <v-col
      v-if="alphabets.length && false"
      md="12"
      class="alphabets-filter d-flex justify-center"
    >
      <div class="alphabets-scrollable">
        <v-btn
          :disabled="searching"
          depressed
          v-on:click="getLineItem(null, 'alphabet')"
          :class="{
            'cyan--text': null == alphabet,
            'cyan white--text': null != alphabet,
          }"
          class="custom-bold-button"
          small
          >All</v-btn
        >
        <v-btn
          :disabled="searching"
          depressed
          v-for="(row, index) in alphabets"
          v-on:click="getLineItem(row, 'alphabet')"
          :class="{
            'cyan--text': row == alphabet,
            'cyan white--text': row != alphabet,
          }"
          class="custom-bold-button"
          small
          :key="index"
          >{{ row }}
          <v-badge
            bordered
            color="error"
            class="custom-margin"
            overlap
            dot
            offset-x="10"
            offset-y="10"
          >
          </v-badge>
        </v-btn>
      </div>
    </v-col>
    <ListingTemplate
      :customClass="'service-listing'"
      v-if="getPermission('adjustment:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('adjustment:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="productBulkAction(item.status)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0">
                <v-layout class="w-100">
                  <v-flex class="mxw-200" v-if="false">
                    <h1 class="form-title d-flex margin-auto">
                      <v-select
                        :disabled="dataLoading"
                        :items="statusList"
                        v-model="status"
                        hide-details
                        item-color="cyan"
                        class="pt-0 mt-0 listing-status main-listing-status-filter"
                        item-text="text"
                        item-value="value"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.description"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              :color="item.color"
                              :text-color="item.textcolor"
                            >
                              <template v-if="item.value == 'all'">{{
                                item.all_services
                              }}</template>
                              <template v-else>{{
                                item.service_status_count
                              }}</template>
                            </v-chip>
                          </v-list-item-action>
                        </template>
                      </v-select>
                    </h1>
                  </v-flex>
                  <v-flex class="mxw-200" v-if="false">
                    <h1 class="form-title d-flex margin-auto">
                      <v-select
                        :disabled="dataLoading"
                        :items="categoryList"
                        v-model="category"
                        v-on:change="getRows()"
                        hide-details
                        item-color="cyan"
                        class="pt-0 mt-0 listing-status main-listing-status-filter"
                        item-text="text"
                        item-value="id"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content
                            v-on:click="setCategoryName(item.text)"
                          >
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              v-if="item.text != 'All Categories'"
                              color="cyan"
                              text-color="white"
                            >
                              <!-- <template v-if="!item.id">{{
                                item.all_products
                              }}</template> -->
                              <template>{{
                                item.quotation_status_count
                              }}</template>
                            </v-chip>
                          </v-list-item-action>
                        </template>
                      </v-select>
                    </h1>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <!-- <template v-if="getPermission('adjustment:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('adjustment.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template> -->
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!-- <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'adjustment',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <v-menu
                  content-class="custom-menu-list"
                  offset-y
                  left
                  v-if="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="exportQuotation"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-database-export</v-icon> Export
                    </v-btn>
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    v-on:change="updateVisible()"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          v-on:change="updateVisible()"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="service"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Report"
          :basicSearchFields="['name', 'barcode', 'reference']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          v-if="false"
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    class="service-listing-row"
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        style="font-size: 1rem !important"
                      >
                        <!--  v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        " -->
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            v-if="false"
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'image'">
                          <template
                            v-if="
                              data.adjustment_image &&
                              data.adjustment_image.file
                            "
                          >
                            <template
                              v-if="
                                isImageextension(
                                  data.adjustment_image.file.name,
                                  data.barcode
                                )
                              "
                            >
                              <v-img
                                class="customer-image"
                                width="70"
                                height="70"
                                contain
                                :lazy-src="$defaultImage"
                                :src="$assetAPIURL(data.adjustment_image)"
                              >
                              </v-img>
                            </template>

                            <template v-else>
                              <div style="height: 50px">
                                <inline-svg
                                  size="50"
                                  style="height: 60px"
                                  :src="
                                    $assetURL(
                                      `media/mime/${getExtension(
                                        data.adjustment_image.file.name
                                      )}.svg`
                                    )
                                  "
                                />
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            <v-img
                              class="customer-image"
                              width="70"
                              height="70"
                              contain
                              :lazy-src="$defaultImage"
                              :src="$assetAPIURL(data.adjustment_image)"
                            >
                            </v-img>
                          </template>
                        </template>

                        <template v-else-if="cols.field == 'date'">
                          {{ formatDate(data.date) }}
                        </template>

                        <!-- <template v-else-if="cols.field == 'date'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.date)"
                            :data="data"
                          >
                           
                            <template v-slot:date_time
                            v-if=""
                            >
                              {{ data.date }}
                            </template>
                            
                          </TableActivity>
                        </template> -->

                        <template v-else-if="cols.field == 'product_barcode'">
                          <Barcode :barcode="data.product_barcode"></Barcode>
                        </template>
                        <template
                          v-else-if="cols.field == 'product_headerName'"
                        >
                          {{ data.product_name }}
                        </template>
                        <template v-else-if="cols.field == 'parent_type_value'">
                          {{ data.type }}
                        </template>

                        <template
                          v-else-if="cols.field == 'adjustment_barcode'"
                        >
                          <Barcode :barcode="data.barcode"></Barcode>
                        </template>

                        <template v-else-if="cols.field == 'supplier'">
                          <div class="customer-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              <template v-if="data.suppliers_display_name">
                                {{ data.suppliers_display_name }}</template
                              >
                              <template v-else>
                                <em class="text-muted"> no display name</em>
                              </template>
                            </p>
                            <p class="m-0">
                              <b>Company: </b>
                              <template
                                v-if="data && data.suppliers_company_name"
                              >
                                {{ data.suppliers_company_name }}
                              </template>
                              <template v-else>
                                <em class="text-muted"> no company name</em>
                              </template>
                            </p>

                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>

                              <template
                                v-if="data && data.suppliers_company_email"
                              >
                                {{ data.suppliers_company_email }}
                              </template>
                              <template v-else>
                                <em class="text-muted"> no email</em>
                              </template>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="data && data.suppliers_company_number"
                              >
                                {{ data.suppliers_company_number }}
                              </template>

                              <template v-else>
                                <em class="text-muted"> no phone</em>
                              </template>
                            </p>
                          </div>
                        </template>

                        <!-- <template v-else-if="cols.field == 'supplier'">
                          <div>
                            <template v-if="data && data.suppliers_barcode">
                              <v-icon small left>mdi-barcode</v-icon>
                              <v-chip
                                x-small
                                label
                                color="cyan white--text"
                                class="px-1"
                              >
                                {{ data.suppliers_barcode }}
                              </v-chip>
                            </template>
                          </div>
                          <div>
                            <template
                              v-if="data && data.suppliers_display_name"
                            >
                              <v-icon small left>mdi-account</v-icon>
                              {{ data.suppliers_display_name }}
                            </template>
                          </div>
                          <div>
                            <template
                              v-if="data && data.suppliers_company_name"
                            >
                              <v-icon small left>mdi-home-city-outline</v-icon>
                              {{ data.suppliers_company_name }}
                            </template>
                          </div>
                          <div>
                            <template
                              v-if="data && data.suppliers_company_email"
                            >
                              <v-icon small left>mdi-email</v-icon>
                              {{ data.suppliers_company_email }}
                            </template>
                          </div>
                          <div>
                            <template
                              v-if="data && data.suppliers_company_number"
                            >
                              <v-icon small left>mdi-phone</v-icon>
                              {{ data.suppliers_company_number }}
                            </template>
                          </div>
                        </template> -->

                        <template v-else-if="cols.field == 'inital_stock'">
                          {{ data.old_stock }}
                        </template>
                        <template v-else-if="cols.field == 'adjusted_quantity'">
                          <template v-if="data.calculation_type == 'Addition'">
                            + {{ data.adjusted_quantity }}
                          </template>
                          <template
                            v-if="data.calculation_type == 'Subtraction'"
                          >
                            - {{ data.adjusted_quantity }}
                          </template>
                        </template>

                        <template
                          v-else-if="cols.field == 'available_quantity'"
                        >
                          {{ data.product_initial_stock }}
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="10" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no reports at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title
            >Filter Product
            <!-- <pre>{{searchableArray}}</pre> --></template
          >
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template>
                    <v-col md="6">
                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        dense
                        :items="SupplierList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier"
                        placeholder="Supplier"
                        v-model="SupplierList_select"
                        @keydown.enter="getRows()"
                        class="mt-0"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Supplier Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <!--   <pre>{{ProductListall}}</pre> -->
                    <v-col md="6">
                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        dense
                        :items="ProductListall"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="product"
                        placeholder="Product"
                        item-text="nameBarcode"
                        item-value="id"
                        v-model="product_select_id"
                        @keydown.enter="getRows()"
                        class="mt-0"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Product Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col md="6">
                      <v-autocomplete
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="Typeadjustment"
                        placeholder="Type"
                        solo
                        flat
                        item-text="name"
                        item-value="name"
                        class="width-100"
                        v-model="typeadjustment_id"
                        @keydown.enter="getRows()"
                      >
                      </v-autocomplete>
                    </v-col>
                    <!-- <v-col md="6">
                      <v-date-picker
                        color="cyan"
                        range
                        :disabled="pageLoading"
                        v-on:change="getRows()"
                        v-model="dates"
                      ></v-date-picker>
                    </v-col> -->

                    <v-col md="6">
                      <v-flex md="2" class="pb-2 mx-2 mx-width-400">
                        <v-menu
                          v-model="datePicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              filled
                              flat
                              label="Date Range"
                              hide-details
                              solo
                              :loading="pageLoading"
                              :disabled="pageLoading"
                              clearable
                              prepend-inner-icon="mdi-calendar"
                              v-on:click:clear="clearFilter('dates')"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :value="formattedDate"
                              color="cyan"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="cyan"
                            range
                            :disabled="pageLoading"
                            v-on:change="getRows()"
                            v-model="dates"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                    </v-col>
                  </template>
                </v-row>

                <!-- <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Service')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row> -->
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="getRows(), (searchDialog = false)"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <template v-if="getPermission('adjustment:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Service</template>
            <template v-slot:body>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 90vh; position: relative"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-row>
                    <template>
                      <a href="./../Sample/services.xlsx" download>
                        <v-btn
                          :disabled="dataLoading"
                          class="mx-2 custom-grey-border custom-bold-button"
                          color="cyan white--text"
                        >
                          Download Sample
                        </v-btn></a
                      >
                    </template>
                  </v-row>
                  <v-row>
                    <template>
                      <div class="container">
                        <form enctype="multipart/form-data" method="POST">
                          <div class="large-4 medium-4 small-4 cell">
                            <label>
                              <input
                                type="file"
                                id="file"
                                ref="file"
                                v-on:change="handleFileUpload()"
                              />
                            </label>
                          </div>
                        </form>
                      </div>
                    </template>
                  </v-row>
                </v-container>
              </perfect-scrollbar>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Service Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import ServiceMixin from "@/core/lib/service/service.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
//import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  DOWNLOAD,
  POST,
  PATCH,
  GET,
} from "@/core/services/store/request.module";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import JwtService from "@/core/services/jwt.service";
import { filter, find, map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin, ServiceMixin],
  name: "adjustment-list",
  data() {
    return {
      exportLoading: false,
      pageLoading: false,
      SupplierList_select: null,
      product_select_id: null,
      typeadjustment_id: null,
      SupplierList: [],
      dates: null,
      ProductListall: [],
      pageModule: "adjustment-listing",
      routeAPI: "reportadjustment",
      routeName: "report",
      routeDetailName: "report.detail",
      status: "all",
      file: "",
      importDialog: false,
      extensionname: null,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
      //category_name:"name",
      searching: false,
      statusList: [],
      customThead: [],
      Typeadjustment: [
        {
          id: 0,
          name: "Purchase Order",
        },
        {
          id: 1,
          name: "Delivery Order",
        },
        {
          id: 2,
          name: "Quotation",
        },
      ],

      allowedSearchFields: [
        "barcode",
        "company_name",
        "person_display_name",
        "unit_no",
        "street_1",
        "street_2",
        "zip_code",
        "city_name",
        "state_name",
        "country_name",
      ],
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      moreActions: [
        {
          title: "Import Service(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /* {
          title: "Export Service(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    PageHeaderCount,
    PageTips,
    Barcode,
    // TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  methods: {
    clearFilter(field) {
      console.log(field, "field");
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getVisits();
    },
    getproduct() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "listing/all" })
        .then((response) => {
          // console.log(response.data.list);
          let Productdata = response.data.list;
          console.log(Productdata, "1222");
          let productnew = map(Productdata, function (row) {
            console.log(row, "row");
            row.nameBarcode = `${row.barcode} - ${row.name}`;
            return row;
          });
          _this.ProductListall = productnew;
          // console.log(_this.ProductListall,"111");

          // _this.ProductListNew = response.data.list;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          // _this.setDefaultProduct();
        });
    },

    isImageextension(ext, index) {
      let extension = ext.split(".");
      this.extensionname = extension[1];
      let data = find(
        this.extensionArray,
        (row) => row == extension.at(-1).toLowerCase()
      );
      console.log({ data });
      if (
        find(
          this.extensionArray,
          (row) => row == extension.at(-1).toLowerCase()
        )
      ) {
        console.log(extension[1], "----" + index);
        return true;
      } else {
        console.log(extension[1], "||||||" + index);
        return false;
      }
    },

    getExtension(ext) {
      let extension = ext.split(".");
      return extension.at(-1);
    },

    getFilteredSupplers(suppliers) {
      const _supplier = filter(suppliers, (row) => row.id);
      return _supplier;
    },
    getTwoCharacter(word) {
      if (word) {
        return word.slice(0, 2).toUpperCase();
      }
      return "";
    },

    formatedateTime(param) {
      return moment(param).format("hh:mm A");
    },
    setCategoryName(name) {
      this.category_name = name;
    },
    productBulkAction(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: _this.selectedRows,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkServicesExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("service");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    exportQuotation() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "services/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "services/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkServicesExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "services/export",
        })
        .then(({ data }) => {
          saveAs(data, "services-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.getproduct();
    this.$store.dispatch(SET_LOCAL_DB).then(() => {
      this.SupplierList = this.$store.state.config.localDB["data"]["suppliers"];
      console.log(this.SupplierList);
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Report",
      },
    ]);

    if (this.getPermission("adjustment:create") == false) {
      let index = this.lodash.findIndex(this.moreActions, {
        action: "import_items",
      });
      if (index >= 0) {
        this.moreActions.splice(index, 1);
      }
    }
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    // _this.defaultColDefs = [
    //   {
    //     headerName: "",
    //     field: "id",
    //     sort: null,
    //     visible: true,
    //     fixed: true,
    //     sortable: false,
    //     checkbox: true,
    //     order: 0,
    //     width: "50px",
    //   },
    //   {
    //     headerName: "Image",
    //     field: "image",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Service #",
    //     field: "barcode",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Service's Info",
    //     field: "service_info",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Price",
    //     field: "price",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Created Time",
    //     child: "created_at",
    //     field: "added_at",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 8,
    //     width: "170px",
    //   },
    //   {
    //     headerName: "Last Modified Time",
    //     child: "modified_at",
    //     field: "updated_at",
    //     sort: null,
    //     visible: false,
    //     fixed: false,
    //     sortable: false,
    //     order: 9,
    //     width: "170px",
    //   },
    // ];

    // const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
    //   visible: true,
    // });

    // _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 15;
  },
};
</script>
